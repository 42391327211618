import React, { useContext, useEffect, useState } from 'react';
import {
  BaseHit,
  Hit,
  TransformItems,
  TransformItemsMetadata,
} from 'instantsearch.js';
import { useHits } from 'react-instantsearch-hooks-web';
import { GlobalStateContext } from '../hooks/globalStateContext';
import { deleteURLParam } from '../pages/search';
('../pages/search');

export interface SearchHitsByCategoryProps {
  location?: any;
  searchTerms?: any;
  updateURLParams?: any;
  currentType?: string;
  escapeHTML?: boolean;
  transformItems?: TransformItems<Hit<BaseHit>, TransformItemsMetadata>;
}

export function SearchHitsByCategory(props: SearchHitsByCategoryProps) {
  const { updateURLParams, currentType } = props;
  const { results } = useHits(props);

  const [type, setType] = useState(null);

  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const searchTerm = searchParams.get('q');
  const searchType = searchParams.get('type');

  const handleCategoryClick = (
    event: React.MouseEvent<HTMLElement>,
    type: string,
  ) => {
    event.preventDefault();
    if (type) {
      setType(type);
    }
    updateURLParams('type', type);
    deleteURLParam('category');
    deleteURLParam('family');
    deleteURLParam('page');
  };

  useEffect(() => {
    setType(currentType);
  }, []);

  const categoryUrl = `${url.origin}${url.pathname}?q=${searchTerm}&type=${type}`;
  return (
    <>
      {results.nbHits > 0 ? (
        <a
          href={categoryUrl}
          className={`${
            currentType === results.index.toLowerCase()
              ? `text-black font-medium`
              : ``
          }`}
          onClick={(event) =>
            handleCategoryClick(event, results.index.toLowerCase())
          }
        >
          {getTypeName(results.index)}
          <span
            id={`${results.index.toLowerCase()}-category-count`}
          >{`: ${results.nbHits}`}</span>
        </a>
      ) : (
        <span className="text-base font-normal text-grey-2">
          {getTypeName(results.index)}
          <span
            id={`${results.index.toLowerCase()}-category-count`}
          >{`: ${results.nbHits}`}</span>
        </span>
      )}
    </>
  );
}

function getTypeName(index: string): React.ReactNode {
  const { microcopy } = useContext(GlobalStateContext);
  switch (index) {
    case 'Products':
      return microcopy?.ProductsFilterTitle ?? 'Products';
    case 'Videos':
      return microcopy?.VideosFilterTitle ?? 'Videos';
    case 'FAQs':
      return microcopy?.FAQFilterTitle ?? 'FAQs';
    case 'Pages':
      return microcopy?.PagesFilterTitle ?? 'Pages';
    default:
      return <></>;
  }
}
