import React, { useContext, useState } from 'react';
import { GlobalStateContext } from '../hooks/globalStateContext';
import {
  classesToApplyForHeader,
  classesToApplyForSearchFilters,
  classesToApplyForSearchFiltersWrap,
} from './SearchMobileButtons';

interface SearchMobileCloseButtonProps {
  className?: string;
}

/**
 * This component builds out the search mobile close button
 * @param props button props.
 * @returns SearchMobileCloseButton
 */

const SearchMobileCloseButton: React.FC<SearchMobileCloseButtonProps> = (
  props,
) => {
  const { className } = props;
  const { microcopy } = useContext(GlobalStateContext);

  const closeSearchFilters = () => {
    const searchFilters = document.getElementById('searchFilters');
    const searchFiltersWrap = document.getElementById('searchFilters-wrap');
    const header = document.getElementById('ryobi-header');

    if (searchFilters) {
      searchFilters.classList.remove(...classesToApplyForSearchFilters.active);
      searchFilters.classList.add(...classesToApplyForSearchFilters.inactive);
    }
    if (searchFiltersWrap) {
      searchFiltersWrap.classList.add(
        ...classesToApplyForSearchFiltersWrap.inactive,
      );
    }
    if (header) {
      header.classList.remove(...classesToApplyForHeader.filtersOpen);
      header.classList.add(...classesToApplyForHeader.filtersClosed);
    }
  };

  return (
    <button
      onClick={closeSearchFilters}
      className={`${
        className ?? ''
      } bg-grey-2 text-black px-xs py-xxs hover:bg-accent-green hover:text-white uppercase font-normal text-xs mb-xs pl-md transition-colors duration-300 desktop:hidden`}
    >
      {microcopy?.FilterMenuCloseText ?? 'Close'}
    </button>
  );
};

export default SearchMobileCloseButton;
