import React, { useContext, useState } from 'react';
import { GlobalStateContext } from '../hooks/globalStateContext';

interface SearchMobileButtonsProps {
  className?: string;
}

export const classesToApplyForSearchFilters = {
  active: [
    'ml-0',
    'top-0',
    'left-0',
    'absolute',
    '!bg-[rgba(235,235,235,0.95)]',
    'h-full',
    'overflow-hidden',
  ],
  inactive: ['-ml-60', 'hidden', '-translate-x-full'],
};

export const classesToApplyForSearchFiltersWrap = {
  active: ['bg-white', 'mt-[25vh]', 'h-screen', 'p-sm', 'mt-xl', 'w-[85vw]'],
  inactive: ['mt-0'],
};

export const classesToApplyForHeader = {
  filtersOpen: ['static'],
  filtersClosed: ['sticky'],
};

/**
 * This component builds out the search mobile buttons - filter results and new search.
 * @param props button props.
 * @returns SearchMobileButtons
 */

const SearchMobileButtons: React.FC<SearchMobileButtonsProps> = (props) => {
  const { className } = props;
  const { microcopy } = useContext(GlobalStateContext);

  const openSearchModal = () => {
    const searchButton = document.getElementById('searchButton');
    if (searchButton) {
      searchButton.click();
    }
  };

  const openSearchFilters = () => {
    const searchFilters = document.getElementById('searchFilters');
    const searchFiltersWrap = document.getElementById('searchFilters-wrap');
    const header = document.getElementById('ryobi-header');
    if (searchFilters) {
      searchFilters.classList.remove(
        ...classesToApplyForSearchFilters.inactive,
      );
      searchFilters.classList.add(...classesToApplyForSearchFilters.active);
    }

    if (searchFiltersWrap) {
      searchFiltersWrap.classList.add(
        ...classesToApplyForSearchFiltersWrap.active,
      );
      searchFiltersWrap.scrollIntoView({ behavior: 'smooth' });
    }

    if (header) {
      header.classList.remove(...classesToApplyForHeader.filtersClosed);
      header.classList.add(...classesToApplyForHeader.filtersOpen);
    }
  };

  return (
    <section className={`${className ?? ''} flex justify-between mb-xs`}>
      <button
        className="bg-grey-2 text-black px-xs py-xxs hover:bg-accent-green hover:text-white uppercase font-normal text-xs"
        onClick={openSearchFilters}
      >
        {microcopy?.FilterResultsTitle ?? 'Filter Results'}
      </button>
      <button
        className="bg-grey-2 text-black px-xs py-xxs hover:bg-accent-green hover:text-white uppercase font-normal text-xs"
        onClick={openSearchModal}
      >
        {microcopy?.NewSearchTitle ?? 'New Search'}
      </button>
    </section>
  );
};

export default SearchMobileButtons;
