import { SearchResults } from 'algoliasearch-helper';
import {
  SortBy,
  TransformItems,
  TransformItemsMetadata,
} from 'instantsearch.js';
import { MenuItem } from 'instantsearch.js/es/connectors/menu/connectMenu';
import React, { useEffect, useState } from 'react';
import { useMenu } from 'react-instantsearch-hooks-web';

interface FilterMenuProps {
  location?: any;
  searchTerms?: string;
  currentSearchType?: string;
  attribute: string;
  isCurrentFilter?: boolean;
  updateURLParams?: any;
  limit?: number;
  showMore?: boolean;
  showMoreLimit?: number;
  sortBy?: SortBy<SearchResults.HierarchicalFacet>;
  transformItems?: TransformItems<MenuItem, TransformItemsMetadata>;
}

export function FilterMenu(props: FilterMenuProps) {
  const {
    location,
    searchTerms,
    currentSearchType,
    attribute,
    updateURLParams,
    isCurrentFilter,
  } = props;
  const { items, refine } = useMenu(props);

  const params = new URLSearchParams(location.search);
  const [category, setCategory] = useState('');
  const [family, setFamily] = useState('');
  const [filter, setFilter] = useState('');

  const currentCategory = params.get('category');
  const currentFamily = params.get('family');

  const handleFilterClick = (
    event: React.MouseEvent<HTMLElement>,
    attribute: string,
    value: string,
  ) => {
    event.preventDefault();
    const filter = attribute === 'productFamilies' ? 'family' : 'category';
    setFilter(filter);
    updateURLParams(filter, value);
    if (attribute === 'productFamilies') {
      setCategory(undefined);
      setFamily(value);
    }
    if (attribute === 'productCategories') {
      setFamily(undefined);
      setCategory(value);
    }
  };

  useEffect(() => {
    setCategory(currentCategory);
    setFamily(currentFamily);
  }, []);

  useEffect(() => {
    if (filter === 'category') {
      setCategory(currentCategory);
      setFamily('');
    } else {
      setFamily(currentFamily);
      setCategory('');
    }
  }, [filter]);

  return (
    <ul className="list-none ml-xs mb-[4px]">
      {items.map((item) => {
        const value = encodeURIComponent(item.value);
        return (
          <li
            key={item.label}
            className=" before:content-['▶'] before:text-[8px] before:text-grey-3 before:pr-[4px]"
          >
            <a
              href={`${location.origin}${location.pathname}?q=${searchTerms}&type=${currentSearchType}&${filter}=${value}`}
              className={`text-sm font-normal ${
                (isCurrentFilter && category === value) ||
                (isCurrentFilter && family === value)
                  ? `text-accent-blue`
                  : `text-grey-3`
              } hover:text-accent-blue`}
              onClick={(event) => handleFilterClick(event, attribute, value)}
            >
              {item.label} ({item.count})
            </a>
          </li>
        );
      })}
    </ul>
  );
}
