import React, { useContext } from 'react';
import { usePagination } from 'react-instantsearch-hooks-web';
import { PaginationConnectorParams } from 'instantsearch.js/es/connectors/pagination/connectPagination';
import { GlobalStateContext } from '../hooks/globalStateContext';

function setPageVariable(page: number) {
  const url = new URL(window.location.href);
  url.searchParams.set('page', page.toString());
  window.history.pushState({}, '', url);
}

function deletePageVariable(variable: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete('page');
  window.history.pushState({}, '', url);
}

export function IndexPagination(props: PaginationConnectorParams) {
  const {
    currentRefinement,
    pages,
    nbPages,
    refine,
    isFirstPage,
    isLastPage,
    createURL,
  } = usePagination(props);

  const { microcopy } = useContext(GlobalStateContext);

  const currentUrl = new URL(window.location.href);
  const searchParams = currentUrl.searchParams;

  const currentPage = searchParams.get('page');

  if (currentPage && parseInt(currentPage) <= nbPages) {
    refine(parseInt(currentPage) - 1);
  } else {
    refine(0);
    deletePageVariable('page');
  }

  return (
    <ul
      className={`flex flex-wrap list-none text-center justify-center mb-xs tablet:mb-0 tablet:text-right tablet:justify-end text-lg ${
        nbPages <= 1 ? `hidden` : ``
      }`}
    >
      {isFirstPage ? null : (
        <li className="py-0 pl-0 tablet:px-xxs">
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              refine(0);
              deletePageVariable('page');
            }}
            className="text-accent-blue hover:text-accent-green capitalize"
          >
            &laquo; {microcopy?.PaginationFirst ?? 'First'}
          </a>
        </li>
      )}
      {currentRefinement === 0 ? null : (
        <li className="py-0 px-xxs">
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              refine(currentRefinement - 1);
              setPageVariable(currentRefinement);
              if (currentRefinement === 1) deletePageVariable('page');
            }}
            className="text-accent-blue hover:text-accent-green"
          >
            &lsaquo; {microcopy?.PaginationPrev ?? 'Prev'}
          </a>
        </li>
      )}
      {currentRefinement >= 5 && <li className="py-0 px-xxs">&hellip;</li>}
      {pages.map((page) => (
        <li key={page} className={`py-0 first:pl-0 px-xxs`}>
          <a
            href={createURL(page)}
            onClick={(event) => {
              event.preventDefault();
              refine(page);
              setPageVariable(page + 1);
            }}
          >
            {page === currentRefinement ? (
              <span className="text-grey-3">{page + 1}</span>
            ) : (
              <span className="text-accent-blue hover:text-accent-green">
                {page + 1}
              </span>
            )}
          </a>
        </li>
      ))}
      {nbPages - currentRefinement >= 5 && (
        <li className="py-0 px-xxs">&hellip;</li>
      )}
      {currentRefinement === nbPages - 1 ? null : (
        <li className="py-0 px-xxs">
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              refine(currentRefinement + 1);
              setPageVariable(currentRefinement + 2);
            }}
            className="text-accent-blue hover:text-accent-green"
          >
            {microcopy?.PaginationNext ?? 'Next'} &rsaquo;
          </a>
        </li>
      )}
      {isLastPage ? null : (
        <li className="py-0 px-xxs">
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              refine(nbPages - 1);
              setPageVariable(nbPages);
            }}
            className="text-accent-blue hover:text-accent-green"
          >
            {microcopy?.PaginationLast ?? 'Last'} &raquo;
          </a>
        </li>
      )}
    </ul>
  );
}
