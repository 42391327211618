import { graphql, PageProps } from 'gatsby';
import * as React from 'react';
import { PageContext } from '../types/pageContext';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, Index } from 'react-instantsearch-hooks-web';
import { SearchHits } from '../components/SearchHits';
import { useContext, useState, useEffect } from 'react';
import { GlobalStateContext } from '../hooks/globalStateContext';
import { FilterMenu } from '../components/SearchFilterMenu';
import { SearchHitsByCategory } from '../components/SearchHitsByCategory';
import useMediaQuery from '../hooks/useMediaQuery';
import SearchMobileCloseButton from '../components/SearchMobileCloseButton';
import SearchMobileButtons from '../components/SearchMobileButtons';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY,
);

export function setURLParam(param: string, value: string) {
  const url = new URL(window.location.href);
  url.searchParams.set(param, value.toString());
  window.history.pushState({}, '', url);
}

export function deleteURLParam(param: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.pushState({}, '', url);
}

export const query = graphql`
  query SearchPage {
    contentfulGlobalOptions {
      ...GlobalFragment
      pageNotFoundBackgroundImage {
        ...AssetFragment
        gatsbyImageData
      }
    }
    contentfulComponentSiteHeader {
      logo {
        ...AssetFragment
      }
      userNavigation {
        ...NavigationFragment
      }
      mainNavigation {
        ...NavigationFragment
      }
      searchCta {
        ...LinkFragment
      }
    }
    contentfulComponentSiteFooter {
      exclusiveToRetailer {
        ...ContentFlexibleFragment
      }
      legalCopy {
        ...ContentFlexibleFragment
      }
      logo {
        ...AssetFragment
      }
      menus {
        ...NavigationFragment
      }
      signUpForm {
        ...FormFragment
      }
      socials {
        ...NavigationFragment
      }
    }
  }
`;
const SearchPage: React.FC<PageProps<Queries.SearchPageQuery, PageContext>> = (
  props,
) => {
  const { location } = props;
  const { microcopy } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery('(max-width: 978px)');
  const [family, setFamily] = useState(null);
  const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);
  const [searchTerms, setSearchTerms] = useState(null);
  const [algoliaFilters, setAlgoliaFilters] = useState([]);
  // the parameter to return for URL. Example:
  // https://localhost:8000/?parameter1=firstParam&parameter2=secondParam

  let filters: string[] = [];

  const updateURLParams = (param: string, value: string) => {
    switch (param) {
      case 'family':
        setFamily(value);
        setCategory(null);
        deleteURLParam('category');
        setURLParam(param, value);
        break;
      case 'category':
        setCategory(value);
        setFamily(null);
        deleteURLParam('family');
        setURLParam(param, value);
        break;
      case 'type':
        setType(value);
        deleteURLParam('family');
        deleteURLParam('category');
        deleteURLParam('page');
        setFamily(null);
        setCategory(null);
        setURLParam(param, value);
        break;
      case 'page':
        setURLParam(param, value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchTerm = params.get('q');
    const currentCategory = params.get('category');
    const currentFamily = params.get('family');
    const currentType = params.get('type');

    if (searchTerm) setSearchTerms(searchTerm);
    if (currentType) {
      setType(currentType);
    } else {
      setType(null);
    }
    if (currentFamily) setFamily(currentFamily);
    if (currentCategory) setCategory(currentCategory);
  }, [location.search]);

  useEffect(() => {
    if (category)
      filters.push(`productCategories:${decodeURIComponent(category)}`);
    setAlgoliaFilters(filters);
  }, [category]);

  useEffect(() => {
    filters = [];
    if (family) filters.push(`productFamilies:${decodeURIComponent(family)}`);
    setAlgoliaFilters(filters);
  }, [family]);

  return (
    <div className="search-container">
      <section className={`container pt-md pb-xxs tablet:py-md`}>
        <h1 className="text-5xl pb-md uppercase font-black border-b border-b-ryobi-green">
          {type && `${type} `}
          <q>{searchTerms && searchTerms}</q>
        </h1>
      </section>

      <InstantSearch indexName="Products" searchClient={searchClient}>
        <div className="container tablet:flex">
          <div
            className={`filters desktop:block w-full desktop:w-1/5 tablet:pb-lg ${
              isMobile === true &&
              `-ml-60 fixed z-30 transition-transform -translate-x-full`
            }`}
            id="searchFilters"
          >
            <div className="filter-wrap pr-sm" id="searchFilters-wrap">
              <SearchMobileCloseButton />
              <h2 className="uppercase text-base text-grey-4 font-normal mb-xxs mt-[4px]">
                {microcopy?.FiltersResultsTitle ?? 'Filter Results'}
              </h2>
              <div className="menu-type">
                <Index indexName={'Products'}>
                  <Configure query={searchTerms} distinct={true} />
                  <ul className="list-none">
                    <li className="text-grey-3 before:content-['▶'] before:text-[8px] before:text-grey-3 before:pr-[4px]">
                      <SearchHitsByCategory
                        currentType={type}
                        updateURLParams={updateURLParams}
                      />
                      {type === 'products' && (
                        <div className="menu-type-submenu ml-[12px]">
                          <h4 className="relative text-base before:inline-block before:align-top font-normal text-grey-4  before:content-['▶'] before:text-[8px] before:text-grey-3 before:pr-[4px]">
                            {microcopy?.ProductFamiliesTitle ??
                              'Product Families'}
                          </h4>
                          <FilterMenu
                            attribute="productFamilies"
                            location={location}
                            searchTerms={searchTerms}
                            currentSearchType={type}
                            updateURLParams={updateURLParams}
                            isCurrentFilter={family ? true : false}
                          />
                          <h4 className="text-base font-normal text-grey-4 before:content-['▶'] before:text-[8px] before:text-grey-3 before:pr-[4px]">
                            {microcopy?.ProductCategoriesTitle ??
                              'Product Categories'}
                          </h4>
                          <FilterMenu
                            attribute="productCategories"
                            location={location}
                            searchTerms={searchTerms}
                            currentSearchType={type}
                            updateURLParams={updateURLParams}
                            isCurrentFilter={category ? true : false}
                          />
                        </div>
                      )}
                    </li>
                  </ul>
                </Index>
              </div>
              <div className="menu-type">
                <Index indexName="FAQs">
                  <Configure query={searchTerms} distinct={true} />
                  <ul className="list-none">
                    <li className="text-grey-3 before:content-['▶'] before:text-[8px] before:text-grey-3 before:pr-[4px]">
                      <SearchHitsByCategory
                        currentType={type}
                        updateURLParams={updateURLParams}
                      />
                    </li>
                  </ul>
                </Index>
              </div>
              <div className="menu-type">
                <Index indexName="Videos">
                  <Configure query={searchTerms} distinct={true} />
                  <ul className="list-none">
                    <li className="text-grey-3 before:content-['▶'] before:text-[8px] before:text-grey-3 before:pr-[4px]">
                      <SearchHitsByCategory
                        currentType={type}
                        updateURLParams={updateURLParams}
                      />
                    </li>
                  </ul>
                </Index>
              </div>
            </div>
          </div>
          <div className="search-results w-full desktop:w-4/5 mb-lg">
            {isMobile && <SearchMobileButtons />}
            <section className="search-results-products">
              {(type === null || type === 'products') && (
                <div className="search-results-products clear-both">
                  <Index indexName={'Products'}>
                    {algoliaFilters.length > 0 ? (
                      <Configure
                        query={searchTerms}
                        hitsPerPage={type === 'products' ? 9 : 6}
                        distinct={true}
                        facetFilters={algoliaFilters}
                      />
                    ) : (
                      <Configure
                        query={searchTerms}
                        hitsPerPage={type === 'products' ? 9 : 6}
                        distinct={true}
                      />
                    )}
                    <SearchHits type={type} />
                  </Index>
                </div>
              )}

              {(type === null || type === 'videos') && (
                <div className="search-results-videos clear-both">
                  <Index indexName={'Videos'}>
                    <Configure
                      query={searchTerms}
                      hitsPerPage={type === 'videos' ? 9 : 3}
                      distinct={true}
                    />
                    <SearchHits type={type} />
                  </Index>
                </div>
              )}

              {(type === null || type === 'faqs') && (
                <div className="search-results-faqs clear-both">
                  <Index indexName={'FAQs'}>
                    <Configure
                      query={searchTerms}
                      hitsPerPage={type === 'faqs' ? 9 : 3}
                      distinct={true}
                    />
                    <SearchHits type={type} />
                  </Index>
                </div>
              )}
              {(type === null || type === 'pages') && (
                <div className="search-results-pages clear-both">
                  <Index indexName="Pages">
                    <Configure
                      query={searchTerms}
                      hitsPerPage={type === 'pages' ? 9 : 3}
                      distinct={true}
                    />
                    <SearchHits type={type} />
                  </Index>
                </div>
              )}
            </section>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
};

export default SearchPage;
