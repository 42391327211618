import React, { useContext, useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import { useHits, UseHitsProps } from 'react-instantsearch-hooks-web';
import ContentfulLink from './contentful/ContentfulLink';
import ContentfulImage from './contentful/ContentfulImage';
import { GlobalStateContext } from '../hooks/globalStateContext';
import {
  BaseHit,
  Hit,
  TransformItems,
  TransformItemsMetadata,
} from 'instantsearch.js';
import { IndexPagination } from './SearchIndexPagination';
import { SearchResults } from 'algoliasearch-helper';
import Modal from 'react-modal';
import Video from './Video';
import SVGCloseButton from '../assets/svgs/close-32x32.svg';

interface ISearchHitProps {
  type?: string;
  escapeHTML?: boolean;
  transformItems?: TransformItems<Hit<BaseHit>, TransformItemsMetadata>;
}
export function SearchHits(props: ISearchHitProps) {
  const { type } = props;
  const { hits, results, sendEvent } = useHits(props);
  const { microcopy } = useContext(GlobalStateContext);
  const [activeModal, setActiveModal] = useState('');
  const [resultType, setResultType] = useState('products');

  const currentUrl = new URL(window.location.href);
  const searchParams = currentUrl.searchParams;
  const currentType = type; //searchParams.get('type');

  // new value of "type" is set to "products"
  if (results.index) {
    searchParams.set('type', results.index.toLowerCase());
  }

  // change the search property of the main url
  currentUrl.search = searchParams.toString();

  // the new url string
  const finalTypeUrl = currentUrl.toString();

  useEffect(() => {
    setResultType(type);
  }, [type]);

  const closeModal = () => {
    setActiveModal('');
  };

  const handleVideoClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const modalTarget = event.currentTarget.attributes[2];
    setActiveModal(modalTarget.nodeValue);
  };

  const noResultsFound: JSX.Element = (
    <div className="w-full">
      <p className="ml-xs">
        {microcopy?.NoResultsFoundText ?? 'No results found'}
      </p>
    </div>
  );

  const renderSearchIndex = (
    hits: any[],
    results: SearchResults<Hit<BaseHit>>,
  ) => {
    switch (results.index) {
      case 'Products':
        return (
          <>
            {hits?.map((hit) => {
              const pathname = `products/details/${hit.slug}`;
              return (
                <div className={`w-full tablet:w-1/3 mb-md`} key={hit.objectID}>
                  <div className="">
                    <ProductCard
                      name={hit.title as string}
                      pathname={pathname}
                      model={hit.model as string}
                      featureImage={hit.featuredImage as string}
                      isCategory={false}
                      availableFrom={hit.availableFrom as string}
                    />
                  </div>
                </div>
              );
            })}
          </>
        );
      case 'Videos':
        return (
          <>
            {hits?.map((hit) => {
              const videoObject = {
                url: hit.video,
              };
              return (
                <React.Fragment key={hit.objectID}>
                  {hit.video && (
                    <div className={`w-full tablet:w-1/3`}>
                      <div className="p-xs">
                        <a
                          href="#"
                          data-video-url={hit.video}
                          data-video-modal-id={`${hit.objectID}`}
                          className="modal-video group"
                          onClick={handleVideoClick}
                        >
                          <div
                            className={`w-full h-full overflow-hidden duration-300`}
                          >
                            <ContentfulImage
                              image={hit.featuredImage}
                              className="!block w-full h-full min-h-full duration-300 group-hover:ease-in-out group-hover:scale-105"
                              alt={hit.title}
                            />
                          </div>
                          <h4
                            className="bg-grey-1 text-center text-sm uppercase font-normal text-accent-blue group-hover:text-accent-green py-4 px-[5%]"
                            id={`heading-${hit.objectID}`}
                          >
                            {hit.title}
                          </h4>
                        </a>

                        {hit.video && (
                          <Modal
                            isOpen={activeModal === hit.objectID}
                            onRequestClose={closeModal}
                            contentLabel={hit.title}
                            aria={{
                              labelledby: `heading-${hit.objectID}`,
                              describedby: `heading-${hit.objectID}`,
                            }}
                            style={{
                              content: {
                                top: '0',
                                left: '0',
                                width: '100%',
                                padding: '0',
                                backgroundColor: 'transparent',
                                border: '0',
                              },
                              overlay: {
                                background: 'rgba(0, 0, 0, 0.45)',
                                zIndex: '50',
                              },
                            }}
                          >
                            <div className="w-full relative grid grid-cols-1 place-content-center h-full">
                              <div className="relative p-xxs w-5/6 desktop:p-xxl desktop:w-3/4 mx-auto animate-fade-in-down-video">
                                <SVGCloseButton
                                  width={32}
                                  height={32}
                                  onClick={closeModal}
                                  className="absolute -top-md right-xxs desktop:top-lg desktop:right-xxl fill-grey-1 cursor-pointer z-20"
                                  alt={
                                    microcopy?.FilterMenuCloseText ??
                                    `Close video modal`
                                  }
                                />
                                <Video
                                  videos={[{ url: hit.video }]}
                                  className={`w-full h-full aspect-16/9 border-grey-1 shadow-[0_0_10px_rgba(0, 0, 0, 0.4)] bg-black`}
                                />
                              </div>
                            </div>
                          </Modal>
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </>
        );
      case 'FAQs':
        return (
          <>
            {hits?.map((hit) => {
              return (
                <div
                  className={`w-full border-b border-b-grey-1 last:border-b-0 pt-md pb-md first:pt-0`}
                  key={hit.objectID}
                >
                  <div className="">
                    <a href={`/${hit.slug}`} className="group">
                      <h5 className="text-black hover:text-black uppercase text-xl mb-xs font-normal">
                        {hit.description}
                      </h5>
                      <div className="text-accent-blue group-hover:text-accent-green leading-7 text-lg">
                        {hit.body}
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
          </>
        );
      case 'Pages':
        return (
          <>
            {hits.length === 0 && noResultsFound}
            {hits?.map((hit) => {
              return (
                <div
                  className={`w-full border-b border-b-grey-1 last:border-b-0 pt-xs pb-xs first:pt-0`}
                  key={hit.objectID}
                >
                  <div className="">
                    {hit.slug && hit.title && (
                      <ContentfulLink link={`/${hit.slug}`} className="group">
                        <h5 className="text-black hover:text-black uppercase text-xl font-normal">
                          {hit.title}
                        </h5>
                      </ContentfulLink>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {results.nbHits > 0 && (
        <>
          <div className="w-full tablet:flex">
            <div className="w-full tablet:w-1/5">
              <h3 className="text-lg text-accent-green uppercase font-medium mb-xxs">
                {results.index}{' '}
                {results.index === 'Products' ? `(${results.nbHits})` : ``}
              </h3>
            </div>
            <div className="w-full tablet:w-4/5 text-right">
              {currentType ? (
                <IndexPagination />
              ) : (
                <ContentfulLink
                  link={finalTypeUrl}
                  className="capitalize text-accent-blue hover:text-accent-green"
                  target="_self"
                >
                  {results.nbPages > 1 &&
                    `${microcopy.SearchSeeAllText ?? `See all`} ${
                      results.nbHits
                    }`}
                </ContentfulLink>
              )}
            </div>
          </div>

          <div className="flex flex-wrap">
            {renderSearchIndex(hits, results)}
          </div>

          {results.index !== 'Pages' && (
            <div className="w-full block border-b border-b-ryobi-green my-sm">
              {/* <hr
                className={`h-[1px] bg-white text-white  ${
                  currentType && `invisible`
                }`}
              /> */}
            </div>
          )}
        </>
      )}
    </>
  );
}
